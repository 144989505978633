import React from 'react';
import styled from 'styled-components'
import * as dsv from './design-system-variables';

/*

H2_FONTFAMILY_STRING_VALUE,
H2_FONTSIZE_STRING_VALUE,
H2_LINEHEIGHT_STRING_VALUE,
H2_PADDINGTOP_STRING_VALUE,
H2_MARGINTOP_STRING_VALUE,
H2_MARGINBOTTOM_STRING_VALUE,
H2_FONTWEIGHT_STRING_VALUE,
H2_LETTERSPACING_STRING_VALUE,
  
  */

export const H1 = styled.h1`
  /* XL  is the default */
  font-family: ${dsv.TYPO_FONT_FAMILY}; 
  font-size: ${dsv.H2_FONTSIZE_STRING_VALUE};
  line-height: ${dsv.H2_LINEHEIGHT_STRING_VALUE};
  padding-top: ${dsv.H2_PADDINGTOP_STRING_VALUE};
  margin-top: ${dsv.H2_MARGINTOP_STRING_VALUE};
  font-weight: ${dsv.H2_FONTWEIGHT_STRING_VALUE};
  margin-bottom: ${dsv.H2_MARGINBOTTOM_STRING_VALUE};
  letter-spacing: ${dsv.H2_LETTERSPACING_STRING_VALUE};

  @media (min-width: ${dsv.SCREEN_MIN_SM}) {
    font-size: ${dsv.H1_FONTSIZE_STRING_VALUE};
    line-height: ${dsv.H1_LINEHEIGHT_STRING_VALUE};
    padding-top: ${dsv.H1_PADDINGTOP_STRING_VALUE};
    margin-top: ${dsv.H1_MARGINTOP_STRING_VALUE};
    font-weight: ${dsv.H1_FONTWEIGHT_STRING_VALUE};
    margin-bottom: ${dsv.H1_MARGINBOTTOM_STRING_VALUE};
    letter-spacing: ${dsv.H1_LETTERSPACING_STRING_VALUE};
  }
`

export const SH1 = styled.span`
  /* XL  is the default */
  display: block;
  font-weight: normal;
  font-size: .65em;
  line-height: ${dsv.H1_LINEHEIGHT_STRING_VALUE};
  margin-top: -${dsv.H2_MARGINBOTTOM_STRING_VALUE};
  margin-bottom: ${dsv.H2_MARGINBOTTOM_STRING_VALUE};
  margin-bottom: calc(${dsv.H2_MARGINBOTTOM_STRING_VALUE} * 2);

  @media (min-width: ${dsv.SCREEN_MIN_SM}) {
    margin-top: -${dsv.H1_MARGINBOTTOM_STRING_VALUE};
    margin-bottom: ${dsv.H1_MARGINBOTTOM_STRING_VALUE};
    margin-bottom: calc(${dsv.H1_MARGINBOTTOM_STRING_VALUE} * 2);
  }
`

export const H2 = styled.h2`
  /* XL  is the default */
  margin: 0;
  padding: 0;
  font-family: ${dsv.TYPO_FONT_FAMILY}; 
  font-size: ${dsv.H2_FONTSIZE_STRING_VALUE};
  line-height: ${dsv.H2_LINEHEIGHT_STRING_VALUE};
  padding-top: ${dsv.H2_PADDINGTOP_STRING_VALUE};
  margin-top: ${dsv.H2_MARGINTOP_STRING_VALUE};
  font-weight: ${dsv.H2_FONTWEIGHT_STRING_VALUE};
  margin-bottom: ${dsv.H2_MARGINBOTTOM_STRING_VALUE};
  letter-spacing: ${dsv.H2_LETTERSPACING_STRING_VALUE};
`

export const H3 = ({children}) => <h3 style={{
  ...dsv.H3,
}}>{children}</h3>;

export const H4 = ({children}) => <h4 style={{
  ...dsv.H4,
}}>{children}</h4>;

export const H5 = ({children}) => <h5 style={{
  ...dsv.H5,
}}>{children}</h5>;

export const H6 = ({children}) => <h6 style={{
  ...dsv.H6,
}}>{children}</h6>;

export const P = ({children, style, onClick}) => <p style={{
  cursor: (onClick) ? 'pointer' : 'default',
  ...dsv.P,
  ...style,
}}
onClick={onClick}
>{children}</p>;

export const PLead = styled.p`
  /* XL  is the default */
  font-family: ${dsv.TYPO_FONT_FAMILY}; 
  font-size: ${dsv.P_FONTSIZE_STRING_VALUE};
  line-height: ${dsv.P_LINEHEIGHT_STRING_VALUE};
  padding-top: ${dsv.P_PADDINGTOP_STRING_VALUE};
  margin-top: ${dsv.P_MARGINTOP_STRING_VALUE};
  font-weight: ${dsv.P_FONTWEIGHT_STRING_VALUE};
  margin-bottom: ${dsv.P_MARGINBOTTOM_STRING_VALUE};
  letter-spacing: ${dsv.P_LETTERSPACING_STRING_VALUE};

  @media (min-width: ${dsv.SCREEN_MIN_SM}) {
    font-size: ${dsv.P_LEAD_FONTSIZE_STRING_VALUE};
    line-height: ${dsv.P_LEAD_LINEHEIGHT_STRING_VALUE};
    padding-top: ${dsv.P_LEAD_PADDINGTOP_STRING_VALUE};
    margin-top: ${dsv.P_LEAD_MARGINTOP_STRING_VALUE};
    font-weight: ${dsv.P_LEAD_FONTWEIGHT_STRING_VALUE};
    margin-bottom: ${dsv.P_LEAD_MARGINBOTTOM_STRING_VALUE};
    letter-spacing: ${dsv.P_LEAD_LETTERSPACING_STRING_VALUE};
  }
`

export const Blockquote = ({children}) => <blockquote style={{
  ...dsv.P_LEAD,
  marginTop: `${dsv.SPACER}rem`,
  marginLeft: `-${dsv.SPACER_HALF}rem`,
  marginRight: `-${dsv.SPACER_HALF}rem`,
  paddingLeft: `${dsv.SPACER_HALF_MINUS_1PX}rem`,
  paddingRight: `${dsv.SPACER_HALF}rem`,
  paddingBottom: dsv.P_LEAD.marginBottom,
  marginBottom: 0,
  borderLeft: `1px solid #000000`,
}}>{children}</blockquote>;

