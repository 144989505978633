import styled from 'styled-components'
import{ 
  SCREEN_MIN_SM, 
  SCREEN_MIN_MD,
  SCREEN_MIN_LG,
  SCREEN_MIN_XL,
  MARGIN_XS,
  MARGIN_SM,
  MARGIN_LG,
  MARGIN_XL,
  SPACER_MINUS_1PX,
  ROOT_FONTSIZE,
} from './design-system-variables';

export const Hero = styled.div`

  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  align-items: stretch;
  /* background-color: #FFF3D9; */

  /* XL  is the default */
  padding: 0 ${MARGIN_XS};

  @media (min-width: ${SCREEN_MIN_SM}) {
    padding: 0 ${MARGIN_SM}; 
  }

  @media (min-width: ${SCREEN_MIN_MD}) {
    padding-left: ${MARGIN_LG};
    padding-right: ${MARGIN_LG};
    flex-direction: row;
    justify-content: space-around;
    padding-bottom: ${2 * Math.round(SPACER_MINUS_1PX * ROOT_FONTSIZE)}px;
    padding-top: ${2 * Math.round(SPACER_MINUS_1PX * ROOT_FONTSIZE)}px;
  }

  @media (min-width: ${SCREEN_MIN_LG}) {
    padding-left: ${MARGIN_XL};
    padding-right: ${MARGIN_XL};
  }

  @media (min-width: ${SCREEN_MIN_XL}) {
    justify-content: space-evenly;
  }

`;

