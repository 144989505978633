import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Page, TextContainer } from '../components/DesignSystem/Page'
import { Hero } from '../components/DesignSystem/Hero'
import {
  H1,
  SH1,
  H2,
  P,
  PLead,
} from '../components/DesignSystem/Heading'

import Layout from '../components/layout'
import SEO from '../components/seo'
import DorlooFarmLogo from '../images/svg/DorlooFarmLogo.svg'

import { 
  SCREEN_MIN_SM, 
  SCREEN_MIN_MD,
  SCREEN_MIN_LG,
  SCREEN_MIN_XL,
  SPACER_MINUS_1PX,
  ROOT_FONTSIZE,
} from '../components/DesignSystem/design-system-variables'

const DorlooFarmLogoContainer = styled.div`

  /* XL  is the default */  
  text-align: center;
  margin: 0;
  padding: 0;
  height: ${8 * Math.round(SPACER_MINUS_1PX * ROOT_FONTSIZE)}px;
  margin-top: ${1 * Math.round(SPACER_MINUS_1PX * ROOT_FONTSIZE)}px;

  @media (min-width: ${SCREEN_MIN_SM}) {
    height: ${12 * Math.round(SPACER_MINUS_1PX * ROOT_FONTSIZE)}px;
    margin-top: ${2 * Math.round(SPACER_MINUS_1PX * ROOT_FONTSIZE)}px;
  }

  @media (min-width: ${SCREEN_MIN_MD}) {
    margin-top: 0;
    padding-right: ${1 * Math.round(SPACER_MINUS_1PX * ROOT_FONTSIZE)}px;
    height: ${17 * Math.round(SPACER_MINUS_1PX * ROOT_FONTSIZE)}px;
  }

  @media (min-width: ${SCREEN_MIN_LG}) {
    padding-right: ${2 * Math.round(SPACER_MINUS_1PX * ROOT_FONTSIZE)}px;
    height: ${20 * Math.round(SPACER_MINUS_1PX * ROOT_FONTSIZE)}px;
  }

  @media (min-width: ${SCREEN_MIN_XL}) {
    height: ${24 * Math.round(SPACER_MINUS_1PX * ROOT_FONTSIZE)}px;
  }

`

const PageTitle = styled.div`
  text-align: center;
  margin-top: ${-1 * Math.round(SPACER_MINUS_1PX * ROOT_FONTSIZE)}px;

  @media (min-width: ${SCREEN_MIN_SM}) {
    margin-top: ${-2 * Math.round(SPACER_MINUS_1PX * ROOT_FONTSIZE)}px;
  }

  @media (min-width: ${SCREEN_MIN_MD}) {
    padding-left: ${1 * Math.round(SPACER_MINUS_1PX * ROOT_FONTSIZE)}px;
    margin-top: ${3 * Math.round(SPACER_MINUS_1PX * ROOT_FONTSIZE)}px;
    max-width: ${16 * Math.round(SPACER_MINUS_1PX * ROOT_FONTSIZE)}px;
  }

  @media (min-width: ${SCREEN_MIN_LG}) {
    padding-left: ${2 * Math.round(SPACER_MINUS_1PX * ROOT_FONTSIZE)}px;
    margin-top: ${4 * Math.round(SPACER_MINUS_1PX * ROOT_FONTSIZE)}px;
  }

  @media (min-width: ${SCREEN_MIN_XL}) {
    margin-top: ${5 * Math.round(SPACER_MINUS_1PX * ROOT_FONTSIZE)}px;
  }

`

const IndexPage = ({ data }) => (
  <Layout>
    <SEO
      title={`${data.site.siteMetadata.longTitle} — ${data.site.siteMetadata.baseline}`}
      keywords={[...data.site.siteMetadata.keywords]}
    />
    <Hero>
        <PageTitle>
          <H1>
            Dorloo
          <SH1>Vegetable Farm
          </SH1>
          </H1>
          <PLead>
            Dorloo Farm is located North West of Cobleskill, New York and 
            specializes in Naturally Grown Vegetables and Flowers.
          </PLead>
        </PageTitle>
        <DorlooFarmLogoContainer><img style={{ height: '100%'}} src={DorlooFarmLogo} alt="Dorloo Farm Logo" /></DorlooFarmLogoContainer>
    </Hero>
    <Page showResponsiveBackgroundColor={false}>
      <TextContainer>
        <H2>Healthy soil for healthy food.</H2>
        <P>When we take care of our soil, we take care of ourselves.</P>
        <P>
          Healthy Soils help retain water, reduce erosion and improve air
          quality and provide an abundant crop of healthy plants, which in turn
          provide healthy food.
        </P>
      </TextContainer>
    </Page>
  </Layout>
)

export const query = graphql`
  query {
    site {
      siteMetadata {
        title,
        longTitle,
        baseline,
        keywords,
      }
    }
  }
`


export default IndexPage
